.tooki__navbar{
    display: flex;
    position: sticky;
    top: 0;
    z-index: 999;
    z-index: 100;
    background-color: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
}
.tooki__navbar-container{
    display: flex;
    width: 100%;
    max-width: 1512px;
    margin: 0 auto;
    padding: 20px 40px;
    justify-content: space-between;
    align-items: center;
}
.tooki__navbar-container_logo{
    cursor: pointer;
}
.tooki__navbar-container_logo img{
    height: 58px;
    width: 235px;
}
.tooki__navbar-container_logo p{
    font-size: 7px;
    margin-top: -1rem;
}
.tooki__navbar-container_header ul{
    display: flex;
    list-style: none;
}
.tooki__navbar-container_header ul li a{
    margin-right: 2rem;
    cursor: pointer;
    text-decoration: none;
    color: #000;
}
.tooki__navbar-container_menu{
    display: none;
}
.tooki__navbar-container_menu-items{ 
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: center;
    left: 0px;
    position: absolute;
    top: 100%;
    width: 100%;
    background: rgb(19, 19, 19);
}
.tooki__navbar-container_menu-items ul{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tooki__navbar-container_menu-items ul li a{
    list-style: none;
    text-decoration: none;
    color: white;

    font-size: 25px;
    cursor: pointer;
    text-decoration: none;
}
.tooki__navbar-container_menu-items ul li{
    margin-bottom: 1.5rem;
}
.tooki__navbar-container_menu-items ul li a:hover{
    color: palevioletred;
}

@media screen and (max-width:1150px) {
    .tooki__navbar-container_header{
        display: none;
    }
    .tooki__navbar-container_menu{
        display: flex;
    }
    .tooki__navbar-container{
        justify-content: space-between;
    }
}
@media screen and (max-width:600px) {
    .tooki__navbar-container_logo img{
        height: 55px;
        width: 220px;
    }
}
@media screen and (max-width:500px) {
    .tooki__navbar-container_logo img{
        height: 50px;
        width: 180px;
    }
}
@media screen and (max-width:430px) {
    .tooki__navbar-container_logo img{
        height: 45px;
        width: 160px;
    }
}
@media screen and (max-width:380px) {
    .tooki__navbar-container_logo img{
        height: 40px;
        width: 130px;
    }
}
@media screen and (max-width:300px) {
    .tooki__navbar-container_logo img{
        height: 30px;
        width: 90px;
    }
}
.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
 @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  