

@media (max-width: 768px) {
    .btn-primary{
        background-color: black!important;
        color: white !important;
        border: none !important;
        padding: 0 !important;
        font-size: 25px !important;
    }
    .dropdown-menu {
      /* Mobile styles */
      background-color: black !important;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
  }
  
  @media (min-width: 769px) {
    .btn-primary{
        background-color: white !important;
        color: black !important;
        border: none !important;
        padding: 0 !important;
        margin-right: 2rem !important;
    }
    .dropdown-menu {
      /* Desktop styles */
      background-color: whitesmoke !important;
      border: none;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }
  }
  